<template>
  <b-card class="blog-edit-wrapper">
    <div class="text-center">
      <h4 class="mt-5">
        تحديث أسعارًا محددة
       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <!-- form -->
    <validation-observer ref="formvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save" ref="form">
        <b-row>
      
          <b-col md="9">
            <b-form-group
            label="زوار من "
              label-for="blog-edit-category"
              class="mb-2"
            >
               <validation-provider
                  #default="{ errors }"
                  name="Category"
                  rules=""
                >
              <v-select
                id="blog-edit-"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addCoursePrice.country"
                label="name"
                :options="VisitorList"
                :reduce="(val) => val.id"
                >
                <template #no-options="{ search, searching, loading }">
                    يتم تحميل البيانات ...
    </template>
  </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          </b-row>
          <b-row>
          <!-- <label>Visitors To</label> -->
              <b-col md="3">
            <b-form-group
              label="المدينة"
              label-for="blog-edit-category"
              class="mb-2"
            >
               <validation-provider
                  #default="{ errors }"
                  name="Category"
                  rules=""
                >
              <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addCoursePrice.city"
                    label="name"
                :options="citiesList"
                :reduce="(val) => val.id"
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="الفئة"
              label-for="blog-edit-category"
              class="mb-2"
            >
               <validation-provider
                  #default="{ errors }"
                  name="Category"
                  rules=""
                >
              <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addCoursePrice.category"
                label="type"
                :options="categoriesList"
                :reduce="(val) => val.id"
                @input="getCoursesByCategory(form.category_id)"
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
            <b-col md="3">
            <b-form-group
              label="الدورة"
              label-for="blog-edit-category"
              class="mb-2"
            >
               <validation-provider
                  #default="{ errors }"
                  name="Course"
                  rules=""
                >
              <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addCoursePrice.course"
                label="name"
                :options="coursesList"
                :reduce="(val) => val.id"
               >
                <template #no-options="{ search, searching, loading }">
                    يتم تحميل البيانات ...
    </template>
  </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          </b-row>
          <b-row>
             <b-col md="3">
            <b-form-group
              label="التقييم"
              label-for="blog-edit-category"
              class="mb-2"
            >
               <validation-provider
                  #default="{ errors }"
                  name="rate"
                  rules=""
                >
              <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addCoursePrice.by_rate"
                label="name"
                :options="rateList"
                :reduce="(val) => val.id"
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
            label="الخصم/الاضافة"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="price"
                  rules="required"
                >
                  <v-select
               
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addCoursePrice.add"
                label="name"
                :options="addList"
                :reduce="(val) => val.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
           <b-col md="3">
            <b-form-group
            label="نسبة مئوية"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="price"
                  rules="required"
                >
              <b-form-input
                id="blog-edit-slug"
                type="number"
                v-model="addCoursePrice.percentage"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          </b-row>
          <b-row>
           <b-col md="4" xl="4">
               <b-form-group
               label="تاريخ البدء"
              label-for="blog-edit-course"
              class="mb-2"
            >
               <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
              
                <flat-pickr
                  v-model="addCoursePrice.start_date"
                  class="form-control"
                />
               </validation-provider>

              </b-form-group>
            </b-col>
             <b-col md="4" xl="4">
               <b-form-group
               label="تاريخ الانتهاء"
              label-for="blog-edit-course"
              class="mb-2"
            >
               <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
              
                <flat-pickr
                
                  v-model="addCoursePrice.end_date"
                  class="form-control"
                />
               </validation-provider>

              </b-form-group>
            </b-col>
          
          <b-col cols="12" class="mt-10">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
             حفظ
            </b-button>
            
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
  import "flatpickr/dist/flatpickr.css";
import flatPickr from "vue-flatpickr-component";
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BCardTitle
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";

import { ref, reactive,computed  } from "@vue/composition-api";
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import Vue from "vue";
export default {
  setup() {
    // const store = useStore();
    const addCourseForm = reactive({
      country_id: "",
      course_id: "",
      city_id: "",
      by_rate:'',
      start:'',
      end:'',
      add:'',
      percentage:'',
    
      category_id: "",
    });
    const VisitorList=ref([])
    store.dispatch('price/getVistior')
        .then(response => {
          console.log("response",response.data)
          VisitorList.value=response.data.data})
 
     const addList=ref([
      {
                  name:"Add",
                  id:1
                },
                {
                  name:'Discount',
                  id:0
                }
                ]
     )
     const rateList=ref([{
                  name:"By Rate",
                  id:1
                },
                {
                  name:'By Amount',
                  id:0
                }
                ])
    const Tabimage = ref("");
    const categoriesList=ref([])
     const coursesList=ref([])
     const citiesList=ref([])
       const { route } = useRouter()
      const id = route.value.params.id
      store.dispatch('price/GetSpecifePrice',{id}).then(response => {
         console.log("oasssss", response?.data.data)
           store.commit('price/GET_SPECIFE_PRICE', response?.data.data)
          })
    
      const CoursePrice = computed(() => store.state.price.SpecifePrice);
        //  console.log("CoursePrice",CoursePrice)
    const addCoursePrice=reactive(CoursePrice)
   
      store.dispatch('courses/getAllCoursesClassical')
        .then(response => {
          console.log("response",response.data)
          coursesList.value=response.data.data
       
          
        })
           store.dispatch('cities/getAllcitites')
             .then(response => {
          console.log("cic",response.data)
          citiesList.value=response.data.data
       
          
        })
   
 store.dispatch('categories/AllCategory')
        .then(response => {
          console.log("response",response.data.data)
          categoriesList.value=response.data.data
        })
   
   
     const addCoursePricevalidate=ref()

    const save = () => {
      const formData = new FormData()
      if (addCoursePrice.value.country) {
        formData.append('country_id', addCoursePrice.value.country)
      }
      if (addCoursePrice.value.course) {
        formData.append('course_id', addCoursePrice.value.course)
      }
      if (addCoursePrice.value.category) {
        formData.append('category_id', addCoursePrice.value.category)
      }
      if (addCoursePrice.value.city) {
        formData.append('city_id', addCoursePrice.value.city)
      }
      // if (addCoursePrice.value.by_rate) {
        formData.append('by_rate', addCoursePrice.value.by_rate)
      // }
      // if (addCoursePrice.value.add) {
        formData.append('add', addCoursePrice.value.add)
      // }
    
      if (addCoursePrice.value.percentage) {
        formData.append('percentage', addCoursePrice.value.percentage)
      }
    
     

      formData.append('start_date', addCoursePrice.value.start_date)
      if (addCoursePrice.value.end_date !== null) {
        formData.append('end_date', addCoursePrice.value.end_date)
      }

     
      formData.append('applied', 0)

  
      formData.append("_method", "put");
           store.dispatch('price/UpdateSpecificPrice',{id,formData})
        .then(response => {
           Vue.swal({
            icon: "success",
              title: "تم التحديث",
              confirmButtonText: "حسنا",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "حسنا",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
    };
    return {
     
      
      VisitorList,
     addList,
     rateList,
      addCoursePrice,
 
      addCoursePricevalidate,
      categoriesList,
     coursesList,
     citiesList,
     CoursePrice,
     id,


      save,
       required, email
    };
  },

  components: {
    BCard,
    flatPickr,
    
    BMedia,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    BCardTitle
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
